import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ProjectOption = ({ props, option }) => {
    return (
        <Box component="li" {...props}>
            <Stack direction="column" sx={{ width: '100%' }}>
                <Typography component="p" sx={{ mb: '6px', fontSize: '14px', color: 'black', lineHeight: '16px' }}>
                    {option?.Name}
                </Typography>
                <Stack direction="row" justifyContent="space-between">
                    <Typography component="p" sx={{ fontSize: '10px', color: 'grey.600' }}>
                        {option?.Project}
                    </Typography>
                    <Typography component="p" sx={{ fontSize: '10px', color: 'grey.600' }}>
                        {option?.ExternalId}
                    </Typography>
                </Stack>
            </Stack>
        </Box>
    );
};

export default ProjectOption;
