import { api } from './api';

const ENTITY = "UserAccounts"

export const userAccountsApi = api.injectEndpoints({
   tagTypes: ['Accounts'],
   endpoints: (builder) => ({
      getAccounts: builder.query({
         query: ({ userId }) => {
            const params = {};

            if (userId) {
               params['$filter'] = `UserId eq ${userId}`
            }

            return {
               url: `${ENTITY}`,
               params: { ...params }
            }
         },
         providesTags: [{ type: 'Accounts' }]
      }),
      getAccountOne: builder.query({
         query: ({ userId }) => {

            return {
               url: `Users(${userId})`,
            }
         },
         providesTags: [{ type: 'Accounts' }]
      }),
      addAccount: builder.mutation({
         query: ({ value, userId }) => {
            return {
               url: `${ENTITY}`,
               method: 'POST',
               body: { Account: value, UserId: userId }
            }
         },
         invalidatesTags: [{ type: 'Accounts' }],
      }),
      deleteAccount: builder.mutation({
         query: ({ id }) => {
            return {
               url: `${ENTITY}(${id})`,
               method: 'DELETE'
            }
         },
         invalidatesTags: [{ type: 'Accounts' }],
      }),
   }),
})

export const { useGetAccountsQuery, useGetAccountOneQuery, useAddAccountMutation, useDeleteAccountMutation } = userAccountsApi
