// types
import { createSlice } from '@reduxjs/toolkit';
import { graphApi } from 'services/graph';
import { userSettingsApi } from 'services/userSettings';

// initial state
const initialState = {
   token: null,
   account: null,
   userId: null,
   entities: []
};

// ==============================|| SLICE - MENU ||============================== //

//ACCOUNT FOR MSAL ACCESS_TOKEN NOT FOR PROFILE DATA

const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {
      setAccessToken(state, action) {
         state.token = action.payload;
      },
   },
   extraReducers: (builder) => {
      builder.addMatcher(
         graphApi.endpoints.getAccount.matchFulfilled,
         (state, { payload }) => {
            state.account = payload
         }
      ),
         builder.addMatcher(
            userSettingsApi.endpoints.getAccount.matchFulfilled,
            (state, { payload }) => {
               state.entities = payload.Entities
               state.userId = payload.UserId
            }
         )
   },
});

export default authSlice.reducer;

export const { setAccessToken } = authSlice.actions;

export const getAccessToken = (state) => state.auth.token
export const getAccount = (state) => state.auth.account