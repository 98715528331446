import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'authConfig';
import { useLayoutEffect } from 'react';
import { setAccessToken } from 'store/reducers/auth';
import { useDispatch } from 'react-redux';
import { useGetAccountMutation } from 'services/userSettings';

const TokenInterceptor = ({ children }) => {
    const { instance, accounts, inProgress } = useMsal();
    const [isTokenLoaded, setIsTokenLoaded] = useState(false);
    const [trigger] = useGetAccountMutation();
    const dispatch = useDispatch();

    const handleLogin = (loginType) => {
        if (loginType === 'redirect') {
            instance.loginRedirect(loginRequest).catch((e) => {
                console.log(e);
            });
        }
    };

    useLayoutEffect(() => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance
            .acquireTokenSilent(request)
            .then((response) => {
                dispatch(setAccessToken(response.accessToken));
                setIsTokenLoaded((_) => true);
                trigger();
            })
            .catch((e) => {
                instance
                    .acquireTokenRedirect(request)
                    .then(async (response) => {
                        dispatch(setAccessToken(response.accessToken));
                        setIsTokenLoaded((_) => true);
                        //get userID
                        trigger();
                    })
                    .catch((error) => {
                        instance.logoutRedirect({
                            postLogoutRedirectUri: '/'
                        });
                    });
            });
    }, []);
    return <>{isTokenLoaded ? children : null}</>;
};

export default TokenInterceptor;
