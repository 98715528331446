// material-ui
import { Box, Typography, Divider } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import useMenuItems from './useMenuItems';
import React from 'react';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    const menu = useMenuItems();
    const navGroups = menu.map((item, ind) => {
        switch (item.type) {
            case 'group':
                return <React.Fragment key={item.id} > <NavGroup item={item} /> {menu.length > ind + 1 ? <Divider sx={{ borderColor: "secondary.light" }} /> : null}</ React.Fragment>;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
