// third-party
import { combineReducers } from 'redux';
import { graphApi } from 'services/graph';
import { taskApi } from 'services/task';

// project import
import menu from './menu';
import auth from './auth';
import tracker from './tracker';
import { userSettingsApi } from 'services/userSettings';
import { userAccountsApi } from 'services/userAccounts';
import { activityLogApi } from 'services/activityLog';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, auth, tracker, [taskApi.reducerPath]: taskApi.reducer, [graphApi.reducerPath]: graphApi.reducer, [userSettingsApi.reducerPath]: userSettingsApi.reducer, [userAccountsApi.reducerPath]: userAccountsApi.reducer, [activityLogApi.reducerPath]: activityLogApi.reducer });

export default reducers;
