// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

import { MsalProvider } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Login from 'pages/authentication/Login';
import AccessTokenInterceptor from 'pages/authentication/AccessTokenInterceptor';
import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App({ msalInstance }) {
    const dispatch = useDispatch()
    useEffect(() => {

        const callbackId = msalInstance.addEventCallback(event => {
            if (event.eventType === EventType.LOGIN_SUCCESS) {
                msalInstance.setActiveAccount(event.payload.account)
                dispatch(setAccount(event.payload.account[0]));
            }
        })

        return () => {
            msalInstance.removeEventCallback(callbackId);
        }
    }, [])

    return (
        <MsalProvider instance={msalInstance}>
            <ThemeCustomization>
                <AuthenticatedTemplate>
                    <AccessTokenInterceptor>
                        <ScrollTop>
                            <Routes />
                            <ToastContainer />
                        </ScrollTop>
                    </AccessTokenInterceptor>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>

                    <Login />
                </UnauthenticatedTemplate>
            </ThemeCustomization>
        </MsalProvider>
    )
}

export default App;
