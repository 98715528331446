import { api } from './api';

const ENTITY = '/UserSettings';

export const userSettingsApi = api.injectEndpoints({
   tagTypes: ['Posts'],
   endpoints: (builder) => ({
      getTableSettings: builder.query({
         query: ({ userId }) => {
            const params = {};

            if (userId) {
               params['$filter'] = `UserId eq ${userId}`
            }

            return {
               url: `${ENTITY}`,
               params: params
            }
         },
         providesTags: [{ type: 'Posts' }]
      }),

      getTableSettingOne: builder.query({
         query: ({ name, userId }) => {
            const params = {}
            if (name || userId) {
               params['$filter'] = `tolower(SettingName) eq '${name.toLowerCase()}' and UserId eq ${userId}`
            }

            return {
               url: `${ENTITY}`,
               params
            }
         }
      }),

      getAccount: builder.mutation({
         query: () => {
            return {
               url: "/Users/TimeTracking.DataEntities.GetSecurityDescriptor",
               method: "POST",
            }
         }
      }),

      addTableSetting: builder.mutation({
         query: (data) => {
            return {
               url: `${ENTITY}`,
               method: 'POST',
               body: data
            }
         },
         invalidatesTags: [{ type: 'Posts' }],
      }),

      updateTableSetting: builder.mutation({
         query: ({ data, id, etag }) => {
            let etagFormatted = etag.replaceAll('\\', '')
            return {
               url: `${ENTITY}(${id})`,
               method: 'PATCH',
               body: data,
               headers: { 'If-Match': etagFormatted }
            }
         },
         invalidatesTags: [{ type: 'Posts' }],
      }),

      deleteTableSetting: builder.mutation({
         query: (id) => {
            return {
               url: `${ENTITY}(${id})`,
               method: 'DELETE',
            }
         },
         invalidatesTags: [{ type: 'Posts' }],
      }),
   }),
})

export const { useGetTableSettingsQuery, useAddTableSettingMutation, useUpdateTableSettingMutation, useGetTableSettingOneQuery, useDeleteTableSettingMutation, useGetAccountMutation } = userSettingsApi
