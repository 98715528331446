import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import PageNotFound from 'pages/PageNotFound';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const DashboardDefaultPinned = Loadable(lazy(() => import('pages/dashboard/DashBoardPinned')));
const DashboardAssignedToMe = Loadable(lazy(() => import('pages/assignedToMe')));
const DashboardAssignedToMePinned = Loadable(lazy(() => import('pages/assignedToMe/AssigedToMePinned')));
const ActivityLog = Loadable(lazy(() => import('pages/activityLog')));
const WeeklyLog = Loadable(lazy(() => import('pages/weeklyLog')));
const Profile = Loadable(lazy(() => import('pages/profile')));

// render - sample page
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes =
{
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardAssignedToMe />
        },
        {
            path: ':pinnedTable',
            element: <DashboardAssignedToMePinned />
        },
        {
            path: '/tasks',
            element: <DashboardDefault />

        },
        {
            path: '/tasks/:pinnedTable',
            element: <DashboardDefaultPinned />
        },
        {
            path: 'activity-log',
            element: <ActivityLog />,
        },
        { path: 'weekly-log', element: <WeeklyLog /> },
        {
            path: 'profile',
            element: <Profile />
        },
        {
            path: '*',
            element: <PageNotFound />
        },

    ],
};

export default MainRoutes;
