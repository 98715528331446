import { generateFilterRequest } from 'helpers/tableFilter';
import { api } from './api';


export const taskApi = api.injectEndpoints({
   tagTypes: ['Items'],
   refetchOnMountOrArgChange: true,
   endpoints: (builder) => ({
      getTask: builder.query({
         query: (args, customFilter) => {
            let filter = `Obsolete eq false and Project/Status ne 'Closed'`;

            if (args?.filterValue?.some((item) => !!item.value)) {
               if (filter) {
                  filter += ' and '
               }
               filter += generateFilterRequest(args.filterValue)
            }
            return {
               url: `WorkItems?$expand=UserWorkItems,State,Project,Project($expand=Organization)&$select=Consultant,Developer,Name,CreatedAt,CreatedBy,ModifiedAt,ModifiedBy,Obsolete,ExternalId,Id,Priority,AssignedTo,Status,Url,NormalHoursEstimate,DevelopmentHours,MaximumHoursEstimate&$top=${args?.limit}&$skip=${args?.skip}&$count=true${filter ? '&$filter=' + filter.trim() : ''}`,
               /* params: { '$skip': args?.skip ? args?.skip : 0, '$top': args?.limit ? args?.limit : 0 } */
            }
         },
         providesTags: [{ type: 'Items' }]
      }),

      getTaskOne: builder.query({
         query: (id) => {
            return {
               url: `WorkItems(${id})`,
            }
         },
      }),

      getGlobalParams: builder.query({
         query: () => {
            return {
               url: `GlobalParams`,
            }
         },
      }),

      getTaskDetail: builder.query({
         query: (id) => {
            return {
               url: `WorkItems(${id})`,
               params: { '$expand': 'Project($select=Name;$expand=Organization($select=Name)),State' }
            }
         },
      }),

      getTaskAssignedToMe: builder.query({
         query: (args) => {
            let defaultFilter = `Obsolete eq false and Project/Status ne 'Closed'`;
            let filter = '';

            if (args?.userId) {
               defaultFilter += ' and ';
               defaultFilter += `UserWorkItems/any(item: item/UserId eq ${args.userId})`
            }

            if (args?.filterValue?.some((item) => !!item.value)) {
               filter = generateFilterRequest(args.filterValue)
            }

            if (filter && defaultFilter) {
               defaultFilter += ' and '
            }

            filter = defaultFilter + filter;

            return {
               url: `WorkItems?$expand=UserWorkItems,State,Project,Project($expand=Organization)&$select=Consultant,Developer,CreatedAt,CreatedBy,ModifiedAt,ModifiedBy,Name,Obsolete,ExternalId,Id,Priority,AssignedTo,Status,Url,NormalHoursEstimate,DevelopmentHours,MaximumHoursEstimate&$count=true`,
               params: {
                  '$skip': args?.skip ? args?.skip : 0, '$top': args?.limit ? args?.limit : 0, '$filter': filter.length > 0 ? filter.trim() : ''
               }
            }
         },
         providesTags: [{ type: 'Items' }]
      }),

      getFilterOptions: builder.query({
         query: ({ entity, top, skip, text, fieldName, orgsToFilter }) => {
            let expand = '';
            let filter = '';
            let apply = '';
            const isAssingedTo = entity === 'AssignedTo';
            if (isAssingedTo) {
               apply = text ? `WorkItems?$apply=filter(contains(tolower(AssignedTo),'${text.toLowerCase()}'))/groupby((AssignedTo,Id))&$top=${top}&$count=true` : `WorkItems?$apply=groupby((AssignedTo,Id))&$top=${top}&$count=true`;
            } else {
               filter = text ? `contains(tolower(${fieldName ? fieldName : 'Name'}),'${text.toLowerCase()}')` : '';
               if (orgsToFilter && orgsToFilter.length > 0) {
                  expand = 'Organization($select=Name)';
                  if (text) {
                     filter += ' and ';
                  }
                  filter += '('
                  orgsToFilter.forEach((org, index) => {
                     filter += `tolower(Organization/Name) eq '${org.toLowerCase()}'`;
                     if (orgsToFilter.length - 1 > index) {
                        filter += ' and ';
                     }
                  })
                  filter += ')'
               }
            }
            return {
               url: apply ? apply : `${entity}?$select=${fieldName},Id&${expand ? '$expand=' + expand : ''}${filter ? `${expand ? '&' : ''}$filter=${filter}` : ''}`,
               params: { '$top': top, '$skip': skip, '$count': true, }
            }
         },
         transformResponse: (response, _, args) => {
            const res = [];
            response.value.forEach(item => {
               if (item[args.fieldName] && !res.some(el => el.label === item[args.fieldName])) {
                  res.push({ label: item[args.fieldName], id: item.Id })
               }
            })
            return { value: res, count: response['@odata.count'] }
         },
         providesTags: [{ type: 'FilterList' }]
      }),

      getOrganizations: builder.query({
         query: () => {
            return {
               url: `Organizations`,
               params: { '$select': 'Name,Id', '$top': 50, '$skip': 0 }
            }
         },
         transformResponse: (response) => {
            return response.value.map(item => {
               return { label: item.Name, id: item.Name }
            })
         },
         providesTags: [{ type: 'FilterList' }]
      }),

      getProjects: builder.query({
         query: () => {
            return {
               url: `Projects`,
               params: { '$select': 'Name,Id', '$skip': 0, '$filter': "Status ne 'Closed'" }
            }
         },
         transformResponse: (response) => {
            return response.value.map(item => {
               return { label: item.Name, id: item.Name }
            })
         },
         providesTags: [{ type: 'FilterList' }]
      }),

      getAssignedTo: builder.query({
         query: () => {
            return {
               url: `WorkItems`,
               params: { '$select': 'AssignedTo,Id', '$top': 50, '$skip': 0 }
            }
         },
         transformResponse: (response) => {
            if (response?.value.length === 0) return response;
            let uniqueValues = [];
            response.value.forEach((val) => {
               if (val?.AssignedTo && typeof val?.AssignedTo === 'string' && !uniqueValues.includes(val.AssignedTo.toLowerCase())) {
                  uniqueValues.push(val.AssignedTo.toLowerCase());
               }
            })
            return uniqueValues.map(item => {
               return { label: item.toLowerCase(), id: item.toLowerCase() }
            })
         },
         providesTags: [{ type: 'FilterList' }]
      }),

      getAccountsById: builder.query({
         query: (userId) => {
            return {
               url: `UserAccounts`,
               params: { '$filter': `UserId eq ${userId}` }
            }
         },

         transformResponse: (response) => {
            return response.value.map(item => item.Account)
         }

      }),

      getState: builder.query({
         query: () => {
            return {
               url: `WorkItemState`,
               params: { '$select': 'Name,Id', '$skip': 0 }
            }
         },
         transformResponse: (response) => {
            if (response?.value.length === 0) return response;
            let uniqueValues = [];
            response.value.forEach((val) => {
               if (val?.Name && typeof val?.Name === 'string' && uniqueValues.findIndex(i => i.name === val.Name) === -1) {
                  uniqueValues.push({ name: val.Name, stateId: val.Id });
               }
            })
            return uniqueValues.map(item => {
               return { label: item.name, id: item.name, StateId: item.stateId }
            })
         },
         providesTags: [{ type: 'FilterList' }]
      }),

      getUserWorkItems: builder.query({
         query: (workItemId) => {
            return {
               url: `UserWorkItems`,
               params: { '$expand': 'User,WorkItem', '$skip': 0, '$filter': `WorkItem/Id eq ${workItemId} and User/Active eq true` }
            }
         },
         providesTags: [{ type: 'UserWorkItems' }]
      }),

      addUserWorkItem: builder.mutation({
         query: (body) => {
            return {
               url: `UserWorkItems`,
               method: 'POST',
               body
            }
         },
         invalidatesTags: [{ type: 'Items' }, { type: 'UserWorkItems' }],
      }),

      deleteUserWorkItem: builder.mutation({
         query: (id) => {
            return {
               url: `UserWorkItems(${id})`,
               method: 'DELETE'
            }
         },
         invalidatesTags: [{ type: 'Items' }, { type: 'UserWorkItems' }],
      }),

      getSearchUsers: builder.query({
         query: (search) => {
            return {
               url: `Users?${search ? `$filter=contains(tolower(FullName),'${search.toLowerCase()}')` : ''}`,
               params: { '$top': 20, '$skip': 0 }
            }
         },
      }),

      updateTableEntity: builder.mutation({
         query: ({ entity, id, data, etag }) => {
            etag = etag.replaceAll('\\', '')
            return {
               url: `${entity} (${id})`,
               method: 'PATCH',
               body: data,
               headers: { 'If-Match': etag }
            }
         },
         invalidatesTags: [{ type: 'Items' }, { type: 'FilterList' }],
      }),
   }),
})

export const { useGetTaskQuery, useGetOrganizationsQuery, useGetProjectsQuery, useGetTaskDetailQuery, useGetGlobalParamsQuery, useGetFilterOptionsQuery, useGetTaskAssignedToMeQuery, useGetAssignedToQuery, useGetAccountsByIdQuery, useGetStateQuery, useUpdateTableEntityMutation, useGetUserWorkItemsQuery, useGetSearchUsersQuery, useGetTaskOneQuery, useDeleteUserWorkItemMutation, useAddUserWorkItemMutation } = taskApi
