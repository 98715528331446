import { ONE_MIN_IN_QUANTITY_FORMAT } from "./transactionTimeParser";

const COLUMN_NAME_TO_REQUEST_NAME = {
   Project: 'Project/Name',
   Organization: 'Project/Organization/Name',
   Name: 'Name',
   ExternalId: 'ExternalId',
   Progress: 'Progress',
   AssignedTo: 'AssignedTo',
   State: 'State/Name',
   Task: 'WorkItem/Name',
}

const TABLE_OPERATOR_TO_REQUEST_OPERATOR = {
   contains: ({ entity, value }) => `contains(${entity},${value})`,
   eq: ({ entity, value }) => `${entity} eq ${value}`,
   neq: ({ entity, value }) => `${entity} ne ${value}`,
   inlist: ({ entity, value }) => `${entity} eq ${value}`,
   notinlist: ({ entity, value }) => `${entity} ne ${value}`,
   startsWith: ({ entity, value }) => `startswith(${entity},${value})`,
   endsWith: ({ entity, value }) => `endswith(${entity},${value})`,
   gt: ({ entity, value }) => `${entity} gt ${value}`,
   gte: ({ entity, value }) => `${entity} gt ${value}`,
   lt: ({ entity, value }) => `${entity} lt ${value}`,
   lte: ({ entity, value }) => `${entity} le ${value}`,
   after: ({ entity, value }) => `${entity} gt ${value}`,
   afterOrOn: ({ entity, value }) => `${entity} ge ${value}`,
   before: ({ entity, value }) => `${entity} lt ${value}`,
   beforeOrOn: ({ entity, value }) => `${entity} le ${value}`,
   inrange: ({ entity, value }) => `${entity} ge ${value.start.split('T')[0]} and ${entity} le ${value.end.split('T')[0]}`,
   notinrange: ({ entity, value }) => `${entity} le ${value.start.split('T')[0]} and ${entity} ge ${value.end.split('T')[0]}`,
}

const generateFilter = ({ operator, name, value, type = '' }) => {
   const entity = COLUMN_NAME_TO_REQUEST_NAME[name] ? COLUMN_NAME_TO_REQUEST_NAME[name] : name;
   return TABLE_OPERATOR_TO_REQUEST_OPERATOR[operator](
      {
         entity: typeof value === 'string' && type !== 'date' ? `tolower(trim(${entity}))` : entity,
         value: typeof value === 'string' && type !== 'date' && value.length > 0 ? `'${value.toLocaleLowerCase().trim()}'` : value
      }
   )
}

export const generateFilterRequest = (filterList) => {
   let result = '';
   const filters = [];
   filterList.forEach(filter => {
      if (filter.name === 'Quantity') {
         if (!filter?.value?.match(/^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/)) return;
         let hourse = +filter.value.split(':')[0]
         let minutes = +filter.value.split(':')[1]
         minutes = Math.trunc(minutes * ONE_MIN_IN_QUANTITY_FORMAT);
         let value = +`${hourse}.${minutes}`
         filters.push(generateFilter({ type: filter.type, operator: filter.operator, value: +value, name: filter.name }));
      } else if (Array.isArray(filter?.value) && filter.value.length > 0) {
         let res = '(';
         let isAddOperator = false;
         filter.value.forEach((val, ind) => {
            isAddOperator = filter.value.length - 1 > ind;
            res += `${generateFilter({ operator: filter.operator, value: val, name: filter.name })}${isAddOperator ? ` ${filter.operator === 'notinlist' ? 'and' : 'or'} ` : ''}`
         })
         res += ')'
         filters.push(res);
      } else if (filter.type === 'date' && filter.value) {
         if (typeof filter.value === 'object' && (!filter.value.start || !filter.value.end)) return
         filters.push(`(${generateFilter({ type: filter.type, operator: filter.operator, value: typeof filter.value === 'string' ? filter.value.split('T')[0] : filter.value, name: filter.name })})`);
      }
      else if ((typeof filter?.value === 'string' || typeof filter?.value === 'number') && filter.value) {
         filters.push(generateFilter({ operator: filter.operator, value: filter.value, name: filter.name }));
      }
   })
   let isAddOperator = false
   filters.forEach((fil, index) => {
      isAddOperator = filters.length - 1 > index;
      result += `${fil} ${isAddOperator ? ' and ' : ''}`
   })

   return result;
}

