// project import
import dashboard from './dashboard';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard]
};

export default menuItems;
