import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { debounce } from 'lodash';
import { useGetTaskOptionsQuery } from 'services/activityLog';

const DEFAULT_LIMIT = 20;
const SPACE_SIZE_LEFT_FOR_LOAD = 20;
const MAX_LIMIT = 300;

const ProjectAutocomplete = ({ value, onChange, ...other }) => {
    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(DEFAULT_LIMIT);
    const [selectedTask, setSelectedTask] = useState(null);
    const [open, setOpen] = useState(false);
    const { data: options, isFetching } = useGetTaskOptionsQuery({ search, limit }, { skip: !open });
    const isMaxReached = useRef(false);
    const list = useRef();

    const updateSearhDebounce = debounce((search) => {
        setSearch(search ? search.toLowerCase() : '');
    }, 500);

    const onSearchChange = useCallback((event) => {
        updateSearhDebounce(event?.target?.value);
    }, []);

    useEffect(() => {
        return () => {
            updateSearhDebounce.cancel();
        };
    }, [updateSearhDebounce]);

    const filterOptions = (options, state) => {
        const res = options.filter((item) => {
            return (
                item?.Name?.toLowerCase()?.includes(state.inputValue.toLowerCase()) ||
                item?.Project?.toLowerCase()?.includes(state.inputValue.toLowerCase())
            );
        });
        return res;
    };

    const loadMoreItems = useCallback(
        (event) => {
            if (isFetching) return;
            if (isMaxReached.current) return;
            if (event.target.scrollHeight - event.target.offsetHeight - event.target.scrollTop < SPACE_SIZE_LEFT_FOR_LOAD) {
                if (options.count > limit + DEFAULT_LIMIT) {
                    setLimit((prev) => prev + DEFAULT_LIMIT);
                } else {
                    isMaxReached.current = true;
                    setLimit(options.count > MAX_LIMIT ? MAX_LIMIT : options.count);
                }

                if (event?.target?.scrollTop && list.current) {
                    let x = setTimeout(() => {
                        list.current.scrollTop = event.target.scrollTop;
                        clearTimeout(x);
                    });
                }
            }
        },
        [options, limit, isFetching]
    );

    const onChangeSearch = (_, val) => {
        if (onChange) {
            setSelectedTask(val);
            onChange(val?.Id ? val.Id : null);
        }
    };

    const clearFilters = useCallback(() => {
        setSearch('');
        setLimit(DEFAULT_LIMIT);
        isMaxReached.current = false;
    }, []);

    useEffect(() => {
        setLimit(DEFAULT_LIMIT);
    }, [search]);

    useEffect(() => {
        if (value) {
            setSelectedTask(value);
            setSearch(value?.Name ? value?.Name : '');
        }
    }, [value]);

    useEffect(() => {
        return () => {
            clearFilters();
        };
    }, []);

    return (
        <>
            <Autocomplete
                {...other}
                disablePortal
                id="taskSearch"
                sx={{
                    width: '100%',
                    '& .MuiAutocomplete-endAdornment': {
                        top: 'calc(50% - 18px)',
                        '& button': { width: '32px', borderRadius: '100%', height: '32px' }
                    }
                }}
                freeSolo
                options={options?.value ? options.value : []}
                autoHighlight
                value={selectedTask}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    clearFilters();
                    setOpen(false);
                }}
                onChange={onChangeSearch}
                filterOptions={filterOptions}
                getOptionLabel={(option) => option?.Name}
                loading={!options?.value}
                onInputChange={onSearchChange}
                ListboxProps={{
                    onScroll: loadMoreItems,
                    ref: list
                }}
                renderOption={(props, option) => (
                    <Box {...props} key={option.Id} sx={{ width: '100%', '&:hover': { backgroundColor: '#F5F5F5' } }}>
                        <Stack direction="column" sx={{ width: '100%' }}>
                            <Typography component="p" sx={{ mb: '6px', fontSize: '14px', lineHeight: '16px' }}>
                                {option?.Name}
                            </Typography>
                            <Stack direction="row" justifyContent="space-between">
                                <Typography component="p" sx={{ fontSize: '10px', color: 'grey.600' }}>
                                    {option?.Project}
                                </Typography>
                                <Typography component="p" sx={{ fontSize: '10px', color: 'grey.600' }}>
                                    {option?.ExternalId}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        sx={{ '& input': { paddingRight: '20px !important' }, backgroundColor: '#F5F5F5', p: '5px' }}
                        variant="standard"
                        {...params}
                        placeholder="Enter Task or Project Name"
                    />
                )}
            />
        </>
    );
};

export default ProjectAutocomplete;
