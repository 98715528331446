export const msalConfig = {
   auth: {
      clientId: "0cd03d95-e06f-4162-85b6-f61184c95a04",
      authority: "https://login.microsoftonline.com/93ba8668-bd6b-4801-881a-260d0a7446cc", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI,
   },
   cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
   },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
   scopes: ["user.read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
   graphMeEndpoint: "https://graph.microsoft.com/v1.0/me/"
};