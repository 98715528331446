import { useMemo } from 'react';

export default function useParseTime({ minutes, value }) {

   /*    const isFullFormat = useMemo(() => {
         return value.match(/^(\d+)d (\d+)h (\d+)(?:min|m)$/);
      }, [value]); */
   const isCorrect = useMemo(() => {
      if (!value) return true
      return value.match(/^(-\d{2}|\d{2}):([0-5]\d)$/);
   }, [value]);

   /*    const isNoDaysFormat = useMemo(() => {
         return value.match(/^(\d+)h (\d+)(?:min|m)$/);
      }, [value]); */

   /*    const isNoMinutesFormat = useMemo(() => {
         return value.match(/^(\d+)d (\d+)h$/);
      }, [value]); */

   /*    const isNoHourseFormat = useMemo(() => {
         return value.match(/^(\d+)d (\d+)(?:min|m)$/);
      }, [value]); */

   /*    const isOnlyDaysFormat = useMemo(() => {
         return value.match(/^(\d+)d$/);
      }, [value]); */

   const minutesInRowFormat = useMemo(() => {
      if (isCorrect) {
         let hours = Math.floor(Math.abs(minutes) / 60);
         let min = Math.abs(minutes) % 60;
         return `${Math.sign(minutes) === -1 ? '-' : ''}${hours ? hours <= 9 ? '0' + hours + ':' : hours + ':' : '00:'}${min ? min <= 9 ? '0' + min : min : '00'}`.trim();
      } else {
         return '';
      }
   }, [minutes]);


   const allTimeInMinutes = useMemo(() => {
      if (!value) return null;

      let [hourse, minutes] = value.replace('-', '').split(':')

      return value.includes('-') ? -Math.abs((+hourse * 60) + +minutes) : (+hourse * 60) + +minutes;
   }, [value]);

   const completeTime = useMemo(() => {
      if (allTimeInMinutes && allTimeInMinutes != minutes) {
         let hours = Math.floor(allTimeInMinutes / 60);
         let min = allTimeInMinutes % 60;
         return `${hours ? hours + (hours != 1 ? ' hours' : ' hour') : ''} ${min ? min + (min != 1 ? ' minutes' : ' minute') : ''}`.trim();
      } else {
         let hours = Math.floor(minutes / 60);
         let min = minutes % 60;
         return `${hours ? hours + (hours != 1 ? ' hours' : ' hour') : ''} ${min ? min + (min != 1 ? ' minutes' : ' minute') : ''}`.trim();
      }
   }, [minutes, value]);

   return {
      minutesFromInput: allTimeInMinutes,
      completeTime,
      minutesInRowFormat,
      isCorrectFormat: isCorrect
   }
}
