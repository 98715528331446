import { api } from './api';


export const trackerApi = api.injectEndpoints({
   tagTypes: ['Transaction'],
   endpoints: (builder) => ({
      getCurrentTimeTransaction: builder.query({
         query: ({ userId }) => {
            return {
               url: `TrackedTransactions`,
               params: { $expand: 'WorkItem($select=Name,Id)', $filter: userId ? `UserId eq ${userId}` : '' },
            }
         },
         transformResponse: (response) => {
            if (response.value?.[0]) {
               return response.value[0]
            } else {
               return false
            }
         },
         providesTags: [{ type: 'Transaction' }]
      }),
      getSearchTask: builder.query({
         query: ({ search, limit = 20 }) => {
            let filter = "Project/Status ne 'Closed'";
            filter += search ? ` and (contains(tolower(Name),'${search}') or contains(tolower(Project/Name),'${search}') ${Number(search) ? 'or ExternalId eq ' + Number(search) : ''})` : '';

            return {
               url: `WorkItems?${filter ? '$filter=' + filter.trim() : ''}`,
               params: { $skip: 0, $top: limit, $expand: 'Project($select=Name,AlternativeName,Status)', $select: "Id,Name,ExternalId", $count: 'true', },
            }
         },
         transformResponse: (response) => {
            if (response?.value) {
               return response.value.map((item) => {
                  return { Name: item.Name, Id: item.Id, Project: item.Project.Name, ExternalId: item?.ExternalId }
               })
            } else {
               return false
            }
         },
         providesTags: [{ type: 'Transaction' }]
      }),
      createRecord: builder.mutation({
         query: ({ userId, body }) => {
            return {
               url: `TrackedTransactions`,
               method: 'POST',
               body: body ? { ...body, UserId: userId } : {
                  "UserId": userId,
                  "MinutesQuantity": 0
               },
            }
         },
         invalidatesTags: [{ type: 'Transaction' }]
      }),

      updateTransaction: builder.mutation({
         query: (body) => {
            let etag = body.etag ? body.etag.replaceAll('\\', '') : body.etag
            let Id = body.Id;
            delete body.Id
            delete body.etag
            return {
               url: `TrackedTransactions(${Id})`,
               method: 'PATCH',
               body: body,
               headers: { 'if-match': etag }
            }
         },
         invalidatesTags: [{ type: 'Transaction' }]
      }),

      deleteTransaction: builder.mutation({
         query: (Id) => {
            return {
               url: `TrackedTransactions(${Id})`,
               method: 'DELETE',
            }
         },

         invalidatesTags: [{ type: 'Transaction' }]
      }),

      confirmTransaction: builder.mutation({
         query: (Id) => {
            return {
               url: `TrackedTransactions(${Id})/TimeTracking.DataEntities.ConfirmTime`,
               method: 'POST',
            }
         },
         invalidatesTags: [{ type: 'Transaction' }]
      }),
   }),
})

export const { useUpdateTransactionMutation, useDeleteTransactionMutation, useGetCurrentTimeTransactionQuery, useCreateRecordMutation, useConfirmTransactionMutation, useGetSearchTaskQuery } = trackerApi
