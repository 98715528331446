// third-party
import { configureStore } from '@reduxjs/toolkit';
import { graphApi } from 'services/graph';
import { userSettingsApi } from 'services/userSettings';
import { taskApi } from 'services/task';

// project import
import reducers from './reducers';
import { userAccountsApi } from 'services/userAccounts';
import { activityLogApi } from 'services/activityLog';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(taskApi.middleware).concat(graphApi.middleware).concat(userSettingsApi.middleware).concat(userAccountsApi.middleware).concat(activityLogApi.middleware)
});

const { dispatch } = store;

export { store, dispatch };
