import { generateFilterRequest } from 'helpers/tableFilter';
import { api } from './api';


export const activityLogApi = api.injectEndpoints({
   tagTypes: ['Transaction'],
   endpoints:
      (builder) => ({
         getTransactions: builder.query({
            query: ({ skip = 0, limit = 20, dateRange, filterValue = null, userId }) => {
               let filter = '';
               let dateFilter = '';
               if (dateRange) {
                  dateFilter = dateRange.from === dateRange.to ? `TransDate eq ${dateRange.to} and UserId eq ${userId}` : `(TransDate ge ${dateRange.from} and TransDate le ${dateRange.to} and UserId eq ${userId})`
                  filter += dateFilter;
               }
               if (filterValue) {
                  let res = generateFilterRequest(filterValue);
                  if (res) {
                     filter = filter + ' and ' + res;
                     if (filter.includes('Project/Name')) filter = filter.replaceAll('Project/Name', 'WorkItem/Project/Name')
                     if (filter.includes('Project/Organization/Name')) filter = filter.replaceAll('Project/Organization/Name', 'WorkItem/Project/Organization/Name')
                  }
               }

               return {
                  url: `Transactions`,
                  params: { '$expand': 'WorkItem($expand=Project($select=Name,AlternativeName;$expand=Organization($select=Name)))', '$top': limit, '$skip': skip, '$count': true, '$filter': filter.trim() }
               }
            },

            providesTags: [{ type: 'Transaction' }]
         }),
         getTransactionsWithoutWorkItem: builder.query({
            query: ({ skip = 0, limit = 20, dateRange, userId }) => {
               let filter = '';
               let dateFilter = '';

               if (dateRange) {
                  dateFilter = dateRange.from === dateRange.to ? `(TransDate eq ${dateRange.to} and UserId eq ${userId})` : `(TransDate ge ${dateRange.from} and TransDate le ${dateRange.to} and UserId eq ${userId})`
                  filter += `${dateFilter} and WorkItemId eq null`;
               }

               return {
                  url: `Transactions`,
                  params: { '$top': limit, '$skip': skip, '$count': true, '$filter': filter }
               }
            },
            providesTags: [{ type: 'Transaction' }]
         }),

         getTasksByDate: builder.query({
            query: ({ skip = 0, limit = 20, dateRange, userId = null }) => {
               let dateFilter = '';

               if (dateRange) {
                  dateFilter = dateRange.from === dateRange.to ? `Transactions/any(item: item/TransDate date eq ${dateRange.to} and item/UserId eq ${userId})` : `Transactions/any(item: item/TransDate ge ${dateRange.from} and item/TransDate le ${dateRange.to} and item/UserId eq ${userId})`
               }

               return {
                  url: `WorkItems?${dateFilter ? '$filter=' + dateFilter.trim() : ''}`,
                  params: { '$expand': `Transactions($filter=TransDate ge ${dateRange.from} and TransDate le ${dateRange.to} and UserId eq ${userId}),Project($select=Name)`, '$top': limit, '$skip': skip, '$count': true, /* $filter: `${dateFilter}` */ }
               }
            },
            providesTags: [{ type: 'Transaction' }]
         }),

         getTransactionsByWorkItemId: builder.query({
            query: ({ limit = 5, id }) => {
               return {
                  url: `Transactions`,
                  params: { '$filter': `WorkItemId eq ${id}`, '$top': limit, '$count': true, /*$filter: dateFilter*/ }
               }
            },
            providesTags: [{ type: 'Transaction' }]
         }),

         getTransactionOne: builder.query({
            query: ({ id }) => {
               return {
                  url: `Transactions(${id})`,
                  params: { '$expand': 'WorkItem($expand=Transactions)' }
               }
            },
            providesTags: [{ type: 'Transaction' }]
         }),

         deleteTransactionOne: builder.mutation({
            query: (id) => {
               return {
                  url: `Transactions(${id})`,
                  method: 'DELETE'
               }
            },
            invalidatesTags: [{ type: 'Transaction' }]
         }),

         getTaskOptions: builder.query({
            query: ({ search, limit = 20 }) => {
               let filter = '';

               if (search) {
                  filter += `(Project/Status ne 'Closed' and Obsolete eq false) and (contains(tolower(Name),'${search}') or contains(tolower(Project/Name),'${search}')`;
                  if (Number(search)) {
                     filter += ` or ExternalId eq ${Number(search)}`
                  }
                  filter += ')'
               }

               return {
                  url: `WorkItems?${filter ? '$filter=' + filter.trim() : ''}`,
                  params: { $skip: 0, $top: limit, $expand: 'Project($select=Name,AlternativeName,Status)', $select: "Id,Name,ExternalId", $count: 'true', },
               }
            },
            transformResponse: (response) => {
               if (response?.value) {
                  const value = response.value.map((item) => {
                     return { Name: item.Name, Id: item.Id, Project: item.Project.Name, ExternalId: item?.ExternalId }
                  })
                  return {
                     value, count: response['@odata.count']
                  }
               } else {
                  return false
               }
            },
            providesTags: [{ type: 'Transaction' }]
         }),
         getTaskOptionOne: builder.query({
            query: (id) => {
               return {
                  url: `WorkItems(${id})`,
                  params: { $expand: 'Project($select=Name)', $select: "Id,Name,ExternalId" },
               }
            },
            transformResponse: (response) => {
               if (response) {
                  return {
                     Id: response.Id, Name: response.Name, Project: response.Project?.Name, ExternalId: item?.ExternalId
                  }
               } else {
                  return false
               }
            },
            providesTags: [{ type: 'Transaction' }]
         }),

         getUsersQuantity: builder.query({
            query: (id) => {
               return {
                  url: `Transactions?$apply=filter(WorkItemId eq ${id})/groupby((Quantity,User/FullName,Posted),aggregate(Quantity with sum as TotalQuantity))`,
               }
            },
            transformResponse: (response) => {
               if (response.value) {
                  let res = [];
                  response.value.forEach((value) => {
                     let existElIndex = res.findIndex((user) => user.User.FullName === value.User.FullName)
                     if (existElIndex !== -1) {
                        if (value.Posted) {
                           res[existElIndex]['Posted'] += value.TotalQuantity;
                           res[existElIndex]['NoPosted'] += value.TotalQuantity;
                        } else {
                           res[existElIndex]['NoPosted'] += value.TotalQuantity;
                        }
                     } else {
                        if (value.Posted) {
                           res.push({ ...value, Posted: value.TotalQuantity });

                        } else {
                           res.push({ ...value, NoPosted: value.TotalQuantity, Posted: 0 });
                        }
                     }
                  })
                  return res;
               } else {
                  return []
               }
            },
            providesTags: [{ type: 'Transaction' }]
         }),

         getTaskWidtTransactions: builder.query({
            query: ({ id }) => {
               return {
                  url: `WorkItems(${id})`,
                  params: { $expand: `Transactions` },
               }
            },
            providesTags: [{ type: 'Transaction' }]
         }),

         getQuantityOfTransactionsOnTask: builder.query({
            query: ({ taskId, isPosted = false }) => {
               return {
                  url: `Transactions?$apply=filter(WorkItemId eq ${taskId}${isPosted ? ' and Posted eq true' : ''})/groupby((Quantity),aggregate(Quantity with sum as TotalQuantity))`
               }
            }, transformResponse: (response) => {
               let res = [{ TotalQuantity: 0 }];
               if (response.value) {
                  response.value.forEach((value) => {
                     res[0].TotalQuantity += value.TotalQuantity;
                  })
                  return res;
               } else {
                  return []
               }
            },
            providesTags: [{ type: 'Transaction' }]
         }),

         updateTransactionLog: builder.mutation({
            query: ({ id, body, etag }) => {
               let etagFormatted = etag.replaceAll('\\', '')
               return {
                  url: `Transactions(${id})`,
                  method: 'PATCH',
                  body: body,
                  headers: { 'if-match': etagFormatted }
               }
            },
            invalidatesTags: [{ type: 'Transaction' }]
         }),
         createTransaction: builder.mutation({
            query: ({ body }) => {
               return {
                  url: `Transactions`,
                  method: 'POST',
                  body: { ...body }
               }
            },
            invalidatesTags: [{ type: 'Transaction' }]
         }),
      }),
})

export const { useCreateTransactionMutation, useUpdateTransactionLogMutation, useGetQuantityOfTransactionsOnTaskQuery, useGetUsersQuantityQuery, useGetTransactionsWithoutWorkItemQuery, useGetTransactionsByWorkItemIdQuery, useGetTasksByDateQuery, useDeleteTransactionOneMutation, useGetTransactionsQuery, useGetTaskOptionsQuery, useGetTaskOptionOneQuery, useGetTransactionOneQuery, useGetTaskWidtTransactionsQuery } = activityLogApi
