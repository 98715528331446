import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAccessToken } from 'pages/authentication/getAccessToken';
import { setAccessToken } from 'store/reducers/auth';
import { toast } from "react-toastify";



const UNATHORIZED_CODE = 401;

const baseQuery = fetchBaseQuery({
   baseUrl: `${process.env.REACT_APP_API_URI}data`,
   prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token
      const userId = getState().auth.userId
      //const token = '0AmK3AZhPMVzsKgOPp5dBCsTIfUJ5zFeTQ8pw0p7tcVWz39vxKcyXR';

      // If we have a token set in state, let's assume that we should be passing it.

      //headers.set('Authorization', `Basic ${btoa(`:${token}`)}`)

      headers.set('Authorization', `Bearer ${token}`)
      if (userId) {
         headers.set('X-Run-As', userId);
      }

      return headers;
   }
})

const baseQueryFetch = async (args, api, extraOptions) => {
   let result = await baseQuery(args, api, extraOptions);
   // refresh request if tkoen is expired
   // token refresh automatically and will be in Auth state

   if (result?.error?.status === UNATHORIZED_CODE) {
      getAccessToken().then((token) => {
         api.dispatch(setAccessToken(token))
         let x = setTimeout(async () => {
            result = await baseQuery(args, api, extraOptions)
            clearTimeout(x);
         }, 1000)
      })
   } else if (result?.error) {
      if (result?.error?.data?.error?.message && !(result?.meta?.request?.url?.includes('TrackedTransactions') && result?.error?.status === 412)) {
         toast.error(`Message: ${result?.error?.data?.error?.message}. Status: ${result?.error?.status}. Code: ${result?.error?.data?.error?.code}`);
      }
   }
   return result;
}

export const api = createApi({
   baseQuery: baseQueryFetch,
   endpoints: () => ({})
})