import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useCreateRecordMutation, useGetSearchTaskQuery } from 'services/tracker';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import ProjectOption from 'components/ProjectOption/index';

const StartTrackerForm = ({ startPlaying }) => {
    const userId = useSelector((state) => state.auth.userId);
    const [search, setSearch] = useState('');
    const [selectedTask, setSelectedTask] = useState(null);
    const [comment, setComment] = useState('');
    const [open, setOpen] = useState(false);
    const { data: options, isFetching } = useGetSearchTaskQuery({ search }, { skip: !open });
    const [createTransaction] = useCreateRecordMutation();

    const updateSearhDebounce = debounce((search) => {
        setSearch(search ? search.toLowerCase() : '');
    }, 500);

    const onSearchChange = useCallback(({ target }) => {
        updateSearhDebounce(target.value);
    }, []);

    useEffect(() => {
        return () => {
            updateSearhDebounce.cancel();
        };
    }, [updateSearhDebounce]);

    const filterOptions = (options, state) => {
        const res = options.filter((item) => {
            return (
                item.Name.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                item.Project.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                item?.ExternalId?.toString().includes(state.inputValue.toLowerCase())
            );
        });
        return res;
    };

    const onChangeSearch = (_, val) => {
        setSelectedTask(val);
    };

    const isValidComment = useMemo(() => {
        if (!comment) return true;
        return !!comment.match(/^[a-zA-Z0-9_ -?:/|]{3,128}$/);
    }, [comment]);

    const isDisabledStart = useMemo(() => {
        if (!comment && !selectedTask) {
            return true;
        } else if (comment) {
            return !isValidComment;
        } else if (selectedTask) {
            return false;
        }
    }, [comment, selectedTask]);

    const onStart = async () => {
        let request = { userId };
        if (selectedTask && comment) {
            request.body = { Comment: comment, MinutesQuantity: 0, WorkItemId: selectedTask.Id };
        } else if (selectedTask) {
            request.body = { MinutesQuantity: 0, WorkItemId: selectedTask.Id };
        } else {
            request.body = { Comment: comment, MinutesQuantity: 0 };
        }

        try {
            let res = await createTransaction(request);
            startPlaying(res.data);
        } catch (e) {
            if (e?.status != 401) {
                alert(`An error occurred: ${err?.data?.error?.message}`);
            }
        }
    };

    return (
        <Stack direction="row" alignItems="end">
            <Autocomplete
                disablePortal
                id="taskSearch"
                sx={{
                    width: 240,
                    '& .MuiAutocomplete-endAdornment': {
                        top: 'calc(50% - 18px)',
                        '& button': { width: '32px', borderRadius: '100%', height: '32px' }
                    }
                }}
                options={options && !isFetching ? options : []}
                autoHighlight
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onChange={onChangeSearch}
                filterOptions={filterOptions}
                getOptionLabel={(option) => option?.Name}
                loading={isFetching}
                onInputChange={onSearchChange}
                renderOption={(props, option) => <ProjectOption props={props} option={option} key={option.Id} />}
                renderInput={(params) => <TextField variant="standard" {...params} placeholder="Enter Task or Project Name" />}
            />

            <Typography sx={{ mx: 2 }}>Or</Typography>

            <TextField
                variant="standard"
                onChange={(val) => setComment(val.target.value)}
                error={!isValidComment}
                placeholder="Enter comment for your job"
                sx={{ width: 200, color: isValidComment ? '#F17C7C' : '' }}
            />

            <Button onClick={onStart} variant="contained" sx={{ py: '5px', ml: '13px', lineHeight: '19px' }} disabled={isDisabledStart}>
                Start
            </Button>
        </Stack>
    );
};

export default React.memo(StartTrackerForm);
