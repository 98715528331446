import React from 'react';

import { Grid, Link, Typography } from '@mui/material';

const PageNotFound = () => {
    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75} justifyContent="center">
            <Grid item xs={12} sx={{ mb: 2.25 }}>
                <Typography variant="h1" align="center">
                    Page not found
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mb: 2.25 }}>
                <Link href="/">
                    <Typography variant="h5" align="center">
                        Back to Dashboard
                    </Typography>
                </Link>
            </Grid>
        </Grid>
    );
};

export default PageNotFound;
