// types
import { createSlice } from '@reduxjs/toolkit';


// initial state
const initialState = {
   isPlaying: false,
   isPause: false,
   isOpenModal: false,
   activeTaskId: null,
   curTime: 0,
};

// ==============================|| SLICE - MENU ||============================== //

const trackerSlice = createSlice({
   name: 'tracker',
   initialState,
   reducers: {
      setActiveId(state, action) {
         state.activeTaskId = action.payload;
      },

      setCurTime(state, action) {
         state.curTime = action.payload
      },

      onPlay(state, action) {
         if (state.isPlaying && state.isPause && action.payload == action.payload) {
            state.isPause = false;
         }
         if (state.isPlaying && action.payload != state.activeTaskId && state.curTime >= 1) {
            state.isOpenModal = true;
            state.isPause = false;
            state.isPlaying = false;
         } else {
            state.activeTaskId = action.payload;
            state.isPlaying = true;
            state.isPause = false;
         }
      },

      onPause(state) {
         state.isPause = true;
      },

      onComplete(state) {
         state.isPause = true;
         state.isOpenModal = true;
      },

      setIsOpenModal(state, action) {
         state.isOpenModal = action.payload;
      },

      onConfirmModal(state) {
         state.curTime = 0;
         state.activeTaskId = null;
         state.isPlaying = false;
         state.isPause = false;
         localStorage.removeItem('trackerState')
      },

      onPlayWithoutTask(state) {
         state.isPause = false;
         state.isPlaying = true;
      }

   },
});

export default trackerSlice.reducer;

export const { onPlay, onPause, onComplete, setIsOpenModal, setActiveId, onConfirmModal, onPlayWithoutTask, setCurTime } = trackerSlice.actions;