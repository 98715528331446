import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// Define a service using a base URL and expected endpoints
export const graphApi = createApi({
   reducerPath: 'graphApi',
   baseQuery: fetchBaseQuery({
      baseUrl: 'https://graph.microsoft.com/v1.0/me/',
      prepareHeaders: (headers, { getState }) => {
         headers.set('Authorization', `Bearer ${getState().auth.token}`)

         return headers;
      }
   }),
   endpoints: (builder) => ({
      getAccount: builder.query({
         query: (q) => q ? q : '',
      }),
   }),
})

export const { useGetAccountQuery } = graphApi

