import { useEffect, useState } from 'react'
import { UnorderedListOutlined, PushpinOutlined, UserOutlined, BarChartOutlined, CalendarOutlined } from '@ant-design/icons';

import { useGetTableSettingsQuery } from 'services/userSettings';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';

const menuInstant = [
   {
      id: 'group-dashboard',
      title: '',
      type: 'group',
      children: [
         {
            id: 'dashboard',
            title: 'Assigned To Me',
            type: 'item',
            url: '/',
            icon: UserOutlined,
            breadcrumbs: false
         },
      ]
   },
   {
      id: 'group-all',
      title: '',
      type: 'group',
      children: [
         {
            id: 'tasks',
            title: 'Tasks',
            type: 'item',
            url: '/tasks',
            icon: UnorderedListOutlined,
            color: 'third',
            breadcrumbs: false
         },
      ]
   },
   {
      id: 'activity-log-group',
      title: '',
      type: 'group',
      children: [
         {
            id: 'activity-log',
            title: 'Activity Log',
            type: 'item',
            url: '/activity-log',
            icon: BarChartOutlined,
            color: 'third',
            breadcrumbs: false
         },
         {
            id: 'weekly-log',
            title: 'Weekly Log',
            type: 'sub-item',
            url: '/weekly-log',
            icon: CalendarOutlined,
            color: 'third',
            breadcrumbs: false
         },
      ]
   },

]


const useMenuItems = () => {
   const userId = useSelector(state => state.auth.userId)
   const { data, isFething } = useGetTableSettingsQuery({ userId }, { skip: !userId });
   const [menu, setMenu] = useState(cloneDeep(menuInstant))

   useEffect(() => {
      if (data && data.value.length > 0 && !isFething) {
         const result = cloneDeep(menuInstant)
         data.value.forEach(item => {
            let data;
            try {
               data = JSON.parse(item.Data)
            } catch {
               return
            }

            if (data?.isAssignedTable) {
               let hasInMenu = result[0].children.some((child) => child.id === item.Id)
               if (!hasInMenu) {
                  result[0].children.push({
                     id: item.Id,
                     title: item.SettingName,
                     type: 'sub-item',
                     url: `/${item.SettingName}`,
                     icon: PushpinOutlined,
                     breadcrumbs: false
                  })
               }
            } else {
               let hasInMenu = result[1].children.some((child) => child.id === item.Id)
               if (!hasInMenu) {
                  result[1].children.push({
                     id: item.Id,
                     title: item.SettingName,
                     type: 'sub-item',
                     url: `/tasks/${item.SettingName}`,
                     icon: PushpinOutlined,
                     color: 'third',
                     breadcrumbs: false
                  })
               }
            }
         })
         setMenu(_ => {
            return result
         })
      } else {
         const result = cloneDeep(menuInstant)
         setMenu(_ => {
            return result
         })
      }
   }, [data, isFething])

   return menu
}

export default useMenuItems