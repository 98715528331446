import { Link } from 'react-router-dom';

// material-ui
import { Grid, Stack, Typography } from '@mui/material';

import { useMsal } from '@azure/msal-react';

// project import
import AuthLogin from './auth-forms/AuthLogin';
import AuthWrapper from './AuthWrapper';
import { SignInButton } from 'pages/authentication/SignInButton';
import { useEffect, useLayoutEffect, useState } from 'react';
import { loginRequest } from 'authConfig';

// ================================|| LOGIN ||================================ //

const Login = () => {
    const { instance } = useMsal();
    const [error, setError] = useState('')
    const autoLogin = (loginType) => {
        if (loginType === 'redirect') {
            instance.loginRedirect(loginRequest).catch((e) => {
                setError(_ => e)
            });
        }
    };

    useEffect(() => {
        if (!error) {
            autoLogin('redirect')
        }
    }, [error])

    return <>
        {error ? <>{error} <button onClick={autoLogin('redirect')}>Try again</button></> : null}


    </>
    /*  <AuthWrapper>
         <Grid container spacing={3}>
             <Grid item xs={12}>
                 <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                     <Typography variant="h3">Login</Typography>
                 </Stack>
             </Grid>
             <Grid item xs={12}>
                 <SignInButton />
             </Grid>
         </Grid>
     </AuthWrapper> */
}

export default Login;
